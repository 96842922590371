// import logo from './logo.svg';
import './css/App.css';
import SimpleStorage from './pages/SimpleStorage';

function App() {
  return (
    <div className="App">
      <SimpleStorage/>
    </div>
  );
}

export default App;
