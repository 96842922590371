// https://docs.metamask.io/guide/ethereum-provider.html#using-the-provider

import React, {useState} from 'react'
import {ethers} from 'ethers'
import AutomatedEncryptedENS from '../contracts/AutomatedEncryptedENS.json'
import ETHRegistrarController from '../contracts/ETHRegistrarController.json'
import WETH9 from '../contracts/WETH9.json'

const SimpleStorage = () => {

	// deploy simple storage contract and paste deployed contract address here. This value is local ganache chain
	let addressAutomatedEncryptedENS = '0x52d418B037e440ee576f338cad24F5A7CB4EFbBa';
	let addressETHRegistrarController = '0x283Af0B28c62C092C9727F1Ee09c02CA627EB7F5';
	let addressWETH = "0xB4FBF271143F4FBf7B91A5ded31805e42b2208d6";

	const goerliChainId = 5;

	if(window.ethereum === undefined){
		alert("Metamask is not detected. Install Metamask then try again.");
		window.location.reload(true);
	}

	const providerRead = new ethers.providers.Web3Provider(window.ethereum); //Imported ethers from index.html with "<script src="https://cdn.ethers.io/lib/ethers-5.6.umd.min.js" type="text/javascript"></script>".

	const AutomatedEncryptedENSRead = new ethers.Contract(addressAutomatedEncryptedENS, AutomatedEncryptedENS, providerRead);
	const ETHRegistrarControllerRead = new ethers.Contract(addressETHRegistrarController, ETHRegistrarController, providerRead);
	const WETH9Read = new ethers.Contract(addressWETH, WETH9, providerRead);

	// const signer = provider.getSigner(); //Do this when the user clicks "enableEthereumButton" which will call getAccount() to get the signer private key for the provider.  	

	// const providerRead = new ethers.providers.Web3Provider(window.ethereum); //Imported ethers from index.html with "<script src="https://cdn.ethers.io/lib/ethers-5.6.umd.min.js" type="text/javascript"></script>".

	const [errorMessage, setErrorMessage] = useState(null);
	const [defaultAccount, setDefaultAccount] = useState(null);
	const [connButtonText, setConnButtonText] = useState('🦊 Connect Wallet');

	const [currentContractVal, setCurrentContractVal] = useState('Connect wallet then click button above');
	const [WethAllowanceVal, setWethAllowanceVal] = useState('Connect wallet then click button above');

	const [providerAccount, setProvider] = useState(null);
	const [signer, setSigner] = useState(null);
	const [contract, setContract] = useState(null);
	const [contractWeth, setContractWeth] = useState(null);

	const connectWalletHandler = async () => {
		if (window.ethereum && window.ethereum.isMetaMask) {

			window.ethereum.request({ method: 'eth_requestAccounts'})
			.then(accounts => {
				accountChangedHandler(accounts[0]);
				setConnButtonText(accounts[0].substr(0,5) + "..." +  accounts[0].substr(38,4) );
				setDefaultAccount(accounts[0])
				getWethAllowance(accounts[0]);
			})
			.catch(error => {
				setErrorMessage(error.message);
			
			});

			// let chainId = await getChainIdConnected();
			// alert(JSON.stringify(chainId))

			if(window.ethereum.networkVersion != goerliChainId){
				// alert("You are not on the Goerli Testnet! Please switch to Goerli and refresh page.")
				try{
				  await window.ethereum.request({
					  method: "wallet_switchEthereumChain",
					  params: [{
						 chainId: "0x5"
					  }]
					})
				//   location.reload(); 
					window.location.reload(true);
				  // alert("Failed to add the network at chainId " + goerliChainId + " with wallet_addEthereumChain request. Add the network with https://chainlist.org/ or do it manually. Error log: " + error.message)
				} catch (error) {
				  alert("Failed to add the network at chainId " + goerliChainId + " with wallet_addEthereumChain request. Add the network with https://chainlist.org/ or do it manually. Error log: " + error.message)
				}
			  }

		} else {
			console.log('Need to install MetaMask');
			setErrorMessage('Please install MetaMask browser extension to interact');
		}
	}

	// update account, will cause component re-render
	const accountChangedHandler = (newAccount) => {
		setDefaultAccount(newAccount);
		updateEthers();
	}

	// listen for account changes
	window.ethereum.on('accountsChanged', accountChangedHandler);

	const updateEthers = () => {
		let tempProvider = new ethers.providers.Web3Provider(window.ethereum);
		setProvider(tempProvider);

		let tempSigner = tempProvider.getSigner();
		setSigner(tempSigner);

		let tempContract = new ethers.Contract(addressAutomatedEncryptedENS, AutomatedEncryptedENS, tempSigner);
		setContract(tempContract);	

		let WethContract = new ethers.Contract(addressWETH, WETH9, tempSigner);
		setContractWeth(WethContract);	
	}

	const setHandler = (event) => {

		event.preventDefault(); //Keep this or else the page will refresh.

		if(event.target.setText.value === "") {
			alert("Enter a number.");
			return;
		}
		if(contract === null) {
			alert("Connect your wallet.");
			return;
		}
		
		contract.ownerChangeFeeWETHinWei(event.target.setText.value)
		.then(tx => {
			console.log("Tx submitted: " + tx.hash)
		})
		.catch(e => {
			if (e.code === 4001){
				alert("Transaction request failed.")
			} else{
				alert("Wallet connected is not the contract owner.")
			}
		});
		
	}

	const skimTokens = (event) => {

		event.preventDefault(); //Keep this or else the page will refresh.

		if(event.target.addressText.value=== "") {
			alert("Enter a number.");
			return;
		}
		if(contract === null) {
			alert("Connect your wallet.");
			return;
		}

		console.log(event.target.addressText.value)
		
		contract.skimTokens(event.target.addressText.value)
		.then(tx => {
			console.log("Tx submitted: " + tx.hash)
		})
		.catch(e => {
			if (e.code === 4001){
				alert("Transaction request failed.")
			} else{
				alert("Wallet connected is not the contract owner.")
			}
		});
		
	}

	const skimEther = (event) => {

		event.preventDefault(); //Keep this or else the page will refresh.

		if(contract === null) {
			alert("Connect your wallet.");
			return;
		}
		
		contract.skimEther()
		.then(tx => {
			console.log("Tx submitted: " + tx.hash)
		})
		.catch(e => {
			if (e.code === 4001){
				alert("Transaction request failed.")
			} else{
				alert("Wallet connected is not the contract owner.")
			}
		});
		
	}

	const ensStartCommit = async(event) => {

		event.preventDefault(); //Keep this or else the page will refresh.

		if(event.target.ensName.value === "" || event.target.upperLimit.value === "" || event.target.lowerLimit.value === "") {
			alert("Fill out all inputs.");
			return;
		}
		if(contract === null) {
			alert("Connect your wallet.");
			return;
		}

		console.log(event.target.ensName.value)
		console.log(defaultAccount)

		let commitHash = await ETHRegistrarControllerRead.makeCommitment(
			event.target.ensName.value,
			defaultAccount,
			"0x0000000000000000000000000000000000000000000000000000000000000000")
		console.log(commitHash)

		// console.log("userCommit")
		console.log("upperLimit " + event.target.upperLimit.value)
		console.log("lowerLimit " + event.target.lowerLimit.value)
		
		contract.userCommit(commitHash)
		.then(tx => {
			console.log("Tx submitted: " + tx.hash)
		})
		.catch(e => {
			if (e.code === 4001){
				alert("Transaction request failed.")
			} else{
				alert("Make sure you provide enough WETH approval and have not registered the same name already.")
			}
		});
		
	}

	const ensStopCommit = (event) => {

		event.preventDefault(); //Keep this or else the page will refresh.

		if(event.target.ensName.value=== "") {
			alert("Enter a number.");
			return;
		}
		if(contract === null) {
			alert("Connect your wallet.");
			return;
		}

		console.log(event.target.ensName.value)
		console.log("removeServiceForName")

		if(contract === null) {
			alert("Connect your wallet.");
			return;
		}
		
		contract.removeServiceForName(
			event.target.ensName.value,
			"0x0000000000000000000000000000000000000000000000000000000000000000"
		)
		.then(tx => {
			console.log("Tx submitted: " + tx.hash)
		})
		.catch(e => {
			if (e.code === 4001){
				alert("Transaction request failed.")
			} else{
				alert("You are not the owner for the commitHash.")
			}
		});
		
	}

	const approveWeth = (event) => {

		event.preventDefault(); //Keep this or else the page will refresh.

		if(event.target.wethAllowanceWei.value=== "") {
			alert("Enter a number.");
			return;
		}
		if(contract === null) {
			alert("Connect your wallet.");
			return;
		}

		console.log(event.target.wethAllowanceWei.value)

		if(contract === null) {
			alert("Connect your wallet.");
			return;
		}
		
		contractWeth.approve(
			addressAutomatedEncryptedENS,
			event.target.wethAllowanceWei.value
		)
		.then(tx => {
			console.log("Tx submitted: " + tx.hash)
		})
		.catch(e => {
			if (e.code === 4001){
				alert("Transaction request failed.")
			} else{
				alert("You are not the owner for the commitHash.")
			}
		});
		
	}

	if(window.ethereum.networkVersion == goerliChainId){
		getCommitFeeWei();
	}

	async function getCommitFeeWei() {
		let queueLastCallValue = await AutomatedEncryptedENSRead.commitFeeWei()
		console.log(queueLastCallValue.toString());
		if(queueLastCallValue.toString() === undefined){
			setCurrentContractVal("Install Metamask and select Goerli Testnet to have a Web3 provider to read blockchain data.");
		}
		else{
			setCurrentContractVal(queueLastCallValue.toString());
		}
	}

	async function getWethAllowance(addressConnected: String) {
		// console.log(defaultAccount); // React state not updating. Pass input address connected.
		// if(defaultAccount === null){
		// 	setWethAllowanceVal("REACT STATE UPDATE BUG defaultAccount NULL ON FIRST CLICK. CLICK AGAIN.");
		// 	return;
		// }
		let wethAllowance = await WETH9Read.allowance(addressConnected,addressAutomatedEncryptedENS)
		console.log(wethAllowance.toString());
		if(wethAllowance.toString() === undefined){
			setWethAllowanceVal("Install Metamask and select Goerli Testnet to have a Web3 provider to read blockchain data.");
		}
		else{
			setWethAllowanceVal(wethAllowance.toString());
		}
	}
	  
	return (
		<div>
			<h4> </h4>
			<button className="button buttonConnectMetamask" onClick={connectWalletHandler}>{connButtonText}</button>

			<h5> USER FUNCTIONS </h5>

			<h5> commitFeeWei(): </h5>
			{currentContractVal}

			<h5> WETH allowance(): </h5>
			{WethAllowanceVal}

			<h4> </h4>
			<form onSubmit={approveWeth}>
				<button className="button buttonHighContrast" type={"submit"}> WETH approve(address guy, uint wad) </button>
				<h4> </h4>
				<input id="wethAllowanceWei" type="text" placeholder="input uint256 wei"/>
			</form>			

			<h4> </h4>
			<form onSubmit={ensStartCommit}>
				<button className="button buttonHighContrast" type={"submit"}> ensCommit() </button>
				<h4> </h4>
				<input id="ensName" type="text" placeholder="input string ensName"/>
				<input id="upperLimit" type="text" placeholder="input upperLimit uint256"/>
				<input id="lowerLimit" type="text" placeholder="input lowerLimit uint256"/>
			</form>

			<h4> </h4>
			<form onSubmit={ensStopCommit}>
				<button className="button buttonHighContrast" type={"submit"}> removeServiceForName() </button>
				<h4> </h4>
				<input id="ensName" type="text" placeholder="input string ensName"/>
			</form>

			<h5> OWNER FUNCTIONS </h5>

			<h4> </h4>
			<form onSubmit={setHandler}>
				<button className="button buttonHighContrast" type={"submit"}> ownerChangeFeeWETHinWei(uint256 newCommitFeeWei) </button>
				<h4> </h4>
				<input id="setText" type="text" placeholder="input uint256 value"/>
			</form>

			<h4> </h4>
			<form onSubmit={skimTokens}>
				<button className="button buttonHighContrast" type={"submit"}> skimTokens(address erc20) </button>
				<h4> </h4>
				<input id="addressText" type="text" placeholder="input address erc20"/>
			</form>
			<h4> </h4>

			<h4> </h4>
			<form onSubmit={skimEther}>
				<button className="button buttonHighContrast" type={"submit"}> skimEther() </button>
			</form>
			<h4> </h4>

			{errorMessage}
			<h4> </h4>
			
			<form action="https://github.com/KodexLabs/automated-ens-react-website">
				<input className="button buttonHighContrast" type="submit" value="GitHub" />
			</form>
		
		</div>
	);
}

export default SimpleStorage;